<template>
  <vs-card class="cardx">
    <div slot="header">
      <h4>{{ header }}</h4>
      <p
        class=""
        style="color: rgb(184, 194, 204);"
      >
        {{ desc }}
      </p>
    </div>
    <slot/>
    <vs-row
      vs-type="flex"
      vs-justify="center"
      class="mt-2"
    >
      <vs-col
        vs-type="flex"
        vs-justify="center"
        vs-align="center"
        vs-w="2"
      >
        <vs-button
          type="filled"
          @click.prevent="$emit('configUpdated')"
        >
          حفظ
        </vs-button>
      </vs-col>
    </vs-row>
  </vs-card>
</template>

<script>
export default {
  props: {
    header: {
      type: String,
      required: true
    },
    desc: {
      type: String,
      required: true
    }
  },
  name: "ConfigWrapper"
};
</script>
